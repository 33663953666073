import React from "react";

const Focados = () => (
  <section className="focados">
    <h2>Excelência e <span>alta joalheria</span></h2>
    <div className="text-block">
      <p className="txt-highlight">
        Na busca incessante pela excelência, seguimos os princípios da alta joalheria em cada peça que produzimos.
      </p>
      <p className="txt-highlight">
        Utilizamos apenas materiais de altíssima qualidade, como ouro e platina. Cada joia é adornada com a beleza incomparável de diamantes brilhantes, assim como com pedras preciosas coradas selecionadas com esmero.
      </p>
      <p className="txt-highlight">
        Nossa dedicação à qualidade inclui uma avaliação minuciosa de cada pedra preciosa, garantindo que sua cor, brilho e corte sejam verdadeiramente excepcionais. Comprometidos com o slow fashion, cada joia é confeccionada com a mais alta qualidade e atenção aos detalhes, proporcionando uma experiência única e duradoura.
      </p>
    </div>
  </section>
)


export default Focados

