import React from 'react';

function GalleryList(props) {
  const listItems = props.items.map(img => (
    <li className={"image-" + img.id} key={img.id}>
      <img src={img.src} alt={img.alt} />
      <p>{img.alt}</p>
    </li>
  ))
  return <ul className="gallery">{listItems}</ul>;
}

export default GalleryList
