import React, { useState } from "react";
import GalleryList from "../components/GalleryList";
import Slider from './Slider';
import { engagementRings } from '../components/galleryItems';
import CloseIcon from '../images/svg/close.svg';

const Qualidade = () => {
  const [visible, setVisible] = useState(false);

  const updateState = () => {
    setVisible(!visible);
  }

  return (
    <>
      <section className="qualidade">
        <div className="stack-slider">
          <GalleryList items={engagementRings} />
        </div>
        <blockquote>
          <p>
            Na Zambrana Jewelry, cada joia é uma obra-prima exclusiva. Desde o primeiro rascunho até a confecção meticulosa à mão, cada detalhe é cuidadosamente planejado para representar momentos importantes na vida de quem receberá a joia. Minhas criações são uma síntese perfeita de arte e significado.
          </p>
        </blockquote>
      </section>
    </>
  )
}

export default Qualidade

