import React from "react";

const Joalheiro = () => (
  <section className="joalheiro">
    <h2>
      Sobre o artista
    </h2>
    <h3>Carlos Zambrana</h3>
    <p className="text-box">
      Bem-vindo(a) ao universo inspirador da Zambrana Jewelry, onde o talento inigualável de Carlos Zambrana dá vida a criações excepcionais. Com sua profunda expertise em design, cravação e gravação de joias, Carlos é um artesão habilidoso e experiente, dedicado a superar expectativas e criar verdadeiras obras de arte em cada peça.
    </p>
    <p className="text-box">
      Movido por uma paixão ardente pelo design, Carlos colabora estreitamente com seus clientes para capturar a essência única de sua individualidade e estilo em cada joia personalizada. Sua habilidade artística singular permite transformar conceitos em criações deslumbrantes, combinando harmoniosamente metais preciosos e gemas cuidadosamente selecionadas.
    </p>
    <p className="text-box">
      Na Zambrana Jewelry, valorizamos a busca pela excelência e qualidade excepcional. Com uma atenção meticulosa aos detalhes e um compromisso inabalável com a perfeição, Carlos cria joias que são verdadeiras expressões de beleza e distinção. Cada peça é um testemunho do seu domínio artesanal e seu olhar refinado para criar joias únicas e exclusivas.
    </p>
    <a className="btn" href="https://wa.me/5511991464295?text=Visitei%20o%20seu%20site%20de%20aneis%20de%20noivado" target="_blank" rel="noopener noreferrer">
      Fale comigo pelo whatsapp
    </a>
  </section>
);

export default Joalheiro;
