import ringGoldDiamond from '../images/ring-gold-diamond.png';
import ringPlatinumDiamond from '../images/ring-platinum-diamond.jpg';
import ringGoldParaibaTourmaline from '../images/ring-gold-paraiba-tourmaline.png';
import ringGoldPlatinumAquamarine from '../images/ring-gold-platinum-aquamarine.png';
import ringPlatinumGoldDiamond from '../images/ring-platinum-gold-diamond.jpg';
import ringPlatinumGoldImperialTopaz from '../images/ring-platinum-gold-imperial-topaz.jpg';
import weddingBandGold from '../images/wedding-band-gold.jpg';
import weddingBandGoldDiamonds from '../images/wedding-band-gold-diamonds.jpg';
import weddingBandRoseGold from '../images/wedding-band-rose-gold.jpg';
import ringGoldEmeralds from '../images/ring-gold-emeralds.png'

const engagementRings = [
  {
    id: 1,
    alt: "Anel de noivado platina e diamante",
    src: ringGoldDiamond
  },
  {
    id: 2,
    alt: "Anel de noivado platina, ouro e diamante",
    src: ringPlatinumDiamond
},
{
    id: 3,
    alt: "Anel de noivado platina e diamante",
    src: ringGoldParaibaTourmaline
  },
  {
    id: 4,
    alt: "Anel de noivado platina, ouro e topázio imperial",
    src: ringPlatinumGoldDiamond
  },
  {
    id: 5,
    alt: "Anel de noivado platina, ouro e topázio imperial",
    src: ringPlatinumGoldImperialTopaz
  }
]

const engagementBands = [
  {
    id: 1,
    alt: "Alianças de compromisso",
    src: ringGoldPlatinumAquamarine
  },
  {
    id: 2,
    alt: "Alianças de noivado",
    src: weddingBandGoldDiamonds
  },
  {
    id: 3,
    alt: "Alianças de casamento",
    src: weddingBandRoseGold
  },
  {
    id: 4,
    alt: "Renovação de alianças",
    src: ringGoldEmeralds
  },
  {
    id: 5,
    alt: "Renovação de alianças",
    src: weddingBandGold
  },

]

export {
  engagementRings,
  engagementBands
}
