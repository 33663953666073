import React from "react";
import { useForm, ValidationError } from '@formspree/react';
import MapsIcon from "../images/svg/maps-google-icon.svg";
import StarIcon from "../images/svg/star.svg";

const mapsURL = 'https://goo.gl/maps/MFodyCUF68kfpout6'

const Banner = () => {
  return (
    <section className="banner">
      <a className="ratings" href={mapsURL} target="_blank" rel="noopener noreferrer">
        <MapsIcon />
        <span className="maps-caption">
          <span>
            5.0 <i><StarIcon /> <StarIcon /> <StarIcon /> <StarIcon /> <StarIcon /></i>
          </span>
          Reviews do Google
        </span>
      </a>
      <div className="site-cta">
        <div className="highlight">
          <h1>
            Anéis de noivado
            <span className="glow">excepcionais</span>
          </h1>
        </div>
        <div className="header-contact">
          <p className="message">
          Transformo sonhos em realidade, criando joias únicas que encapsulam toda a beleza e emoção do seu amor
          </p>
          <a className="btn" href="https://wa.me/5511991464295?text=Visitei%20o%20seu%20site%20de%20aneis%20de%20noivado" target="_blank" rel="noopener noreferrer">
            Fale comigo imediatamente
          </a>
          <span className="disclaimer">* A mensagem será enviada para nosso WhatsApp</span>
        </div>
      </div>
    </section>
  )
}

export default Banner

