import React from "react";
import Logo from "../images/svg/zambrana_logo-wt.svg";
import InstagramIcon from "../images/svg/instagram.svg";
import FacebookIcon from "../images/svg/facebook.svg";
import WhatsappIcon from "../images/svg/whatsapp.svg";

const Header = () => (
  <header className="main-header">
    <a className="phone" href="tel:11991464295">(11) 99146.4295</a>
    <figure className="site-logo">
      <Logo />
    </figure>
    <nav className="social-menu">
      <a href="https://www.instagram.com/zambranajewelry/" target="_blank" rel="noopener noreferrer">
        <InstagramIcon />
        <span className="screen-reader">Visite nosso Instagram</span>
      </a>
      <a href="https://www.facebook.com/zambranajewelry" target="_blank" rel="noopener noreferrer">
        <FacebookIcon />
        <span className="screen-reader">Visite nosso Facebook</span>
      </a>
      <a href="https://wa.me/5511991464295?text=Visitei%20o%20seu%20site%20de%20aneis%20de%20noivado" target="_blank" rel="noopener noreferrer">
        <WhatsappIcon />
        <span className="screen-reader">Nos chame no Whatsapp"</span>
      </a>
    </nav>
  </header>
)

export default Header
