import { gsap } from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import animations from "../components/animations";
import Banner from "../components/Banner";
import Focados from "../components/Focados";
import Footer from "../components/Footer";
import Header from '../components/Header';
import Joalheiro from "../components/Joalheiro";
import Qualidade from "../components/Qualidade";
import "../styles/styles.less";

const IndexPage = () => {
  const scrolltop = useRef(null);

  // Scroll smooth
  gsap.registerPlugin(ScrollTrigger);

  useEffect(animations);

  return (
    <main id="viewport" className="scroller" ref={scrolltop}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Marcamos momentos com peças de joalheria únicas, eternas e personalizadas. A Zambrana Joias traz esse site exclusivo para quem busca anéis de noivado, solitários, alianças de casamento e compromisso." />
        <title>Anéis de Noivado personalizados - Zambrana Joias</title>
        <link rel="canonical" href="https://www.joiaszambrana.com.br/noivado/" />
      </Helmet>
      <Header />
      <Banner />
      <Qualidade />
      <Joalheiro />
      <Focados />
      <Footer />
    </main>
  );
}

export default IndexPage
