import React from "react";
import { useForm } from "@formspree/react";
import GalleryList from "../components/GalleryList";
import { engagementBands } from '../components/galleryItems';
import ScroolTopIcon from '../images/svg/arrow_up.svg'

const Footer = () => {

  return (
    <footer className="main-footer">
      <GalleryList items={engagementBands} />
      <div className="sign-in" >
        <h3>Crie seu momento inesquecível</h3>
        <p>
          Oferecemos um atendimento personalizado e consultivo que celebra a diversidade. Valorizamos e acolhemos clientes de diferentes origens e identidades, incluindo pessoas negras, da comunidade LGBTQIA+ e aqueles que não se enquadram nos padrões sociais.
        </p>
        <p>
          Fornecemos orientação especializada e uma experiência única para cada cliente, guiando-os em todo o processo, desde a escolha dos materiais até a entrega da joia finalizada.
        </p>
        <p>
          Sua história e visão são o que nos inspiram a criar joias que capturam a essência da sua singularidade.
        </p>
        <a className="btn" href="https://api.whatsapp.com/send?phone=+5511991464295&text=Ol%C3%A1,%20estou%20interessado%20em%20seu%20produto,%20como%20funciona%20seu%20trabalho?" target="_blank" rel="noopener noreferrer">
          Fale comigo no WhatsApp
        </a>
      </div>
      <button id="scroll-top">
        <ScroolTopIcon />
        <span>Volte ao topo</span>
      </button>
      <address>
        Zambrana Jewelry © 2018-2022 - R. Luís Otávio, 2565 - Parque Taquaral, Campinas - SP, 13087-018
      </address>
    </footer>
  )
}

export default Footer

